@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");


* {
  box-sizing: border-box;
  font-family: "IranYekan";
}

body {
  margin: 0;
  padding: 0;
  background-color: #fff;
  transition: background-color 1s ease-out, color 250ms ease-in-out;
}


.yarl__slide{
  padding: 70px 70px !important;
  padding-bottom: 70px !important;
}

@media only screen and (max-width:700px) {
  .yarl__slide{
    padding: 70px 0px !important;
  padding-bottom: 70px !important;

  } 
}
