:root {
  --inputWith: 26%;
  --registerGreen: #85bb21;
}

@font-face {
  font-family: Estedad;
  src: url("../../../Fonts/Estedad-v7.3/ttf/Estedad-Medium.ttf");
}

* {
  /* font-size: 25px; */
  size: 25px;
  font-family: Estedad;
}

.container {
  margin: 0;
  background-color: rgb(255, 255, 255);
  height: 91vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outerForm {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 80%;
  height: 40%;
}

.paymentForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: var(--inputWith);
}

.titlePayment {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.registerationInfoContainer {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.paymentForm > button {
  margin-top: 20px;
  color: white;
  background-color: #7a27ff;
  border: none;
  border-radius: 6px;
  padding: 5px;
  font-size: 22px;
  width: 100%;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.TextPay {
  padding-left: 5px; padding-right: 5px;
  background-color: rgb(221, 221, 221);
  border-radius: 10px;
}

.innerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.innerForm > input {
  margin-bottom: 7px;
  border: 1px solid rgb(222, 222, 222);
  border-radius: 4px;
  font-size: 20px;
  width: var(--inputWith);
  padding-top: 7px;
  padding-bottom: 7px;
  color: rgb(59, 59, 59);
}

.innerForm > input::placeholder {
  font-size: 15px;
  text-align: right;
  color: rgb(222, 222, 222);
}

.innerForm > input:focus {
  border: 1px solid rgb(222, 222, 222);
  outline: none;
}

.innerForm > button {
  margin-top: 3px;
  color: white;
  background-color: #7a27ff;
  border: none;
  border-radius: 6px;
  padding: 5px;
  font-size: 22px;
  width: var(--inputWith);
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: var(--inputWith);
}

.loginGroup {
  border-radius: 0px 17px 6px 0px;
  width: 100%;
  border: none;
  padding: 10px;
  background-color: #7a27ff;
  color: white;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.loginDeactive {
  background-color: rgb(222, 222, 222) !important;
  color: rgb(164, 164, 164) !important;
}
.signUpDeactive {
  background-color: rgb(222, 222, 222) !important;
  color: rgb(164, 164, 164) !important;
}

.signUpGroup {
  border-radius: 17px 0px 0px 6px;
  width: 100%;
  border: none;
  padding: 10px;
  color: white;
  background-color: var(--registerGreen);
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: var(--inputWith);
  margin-top: 3px;
  margin-bottom: 3px;
}

.inputGroup > input,
select {
  width: 100%;
  margin-left: 3px;
  border: 1px solid rgb(222, 222, 222);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 4px;
  font-size: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: rgb(59, 59, 59);
}

.inputGroup > input::placeholder {
  font-size: 15px;
  text-align: right;
  color: rgb(222, 222, 222);
}

.inputGroup > input:focus {
  border: 1px solid rgb(222, 222, 222);
  outline: none;
}

.registerButton {
  background-color: var(--registerGreen) !important;
}

.disabledRegister {
  background-color: gray !important;
  cursor: default !important;
}

form > p {
  text-decoration: underline;
  cursor: pointer;
}



.welcomeText {
  width: var(--inputWith);
  overflow: auto;
}

@media only screen and (max-width: 500px) {
  :root {
    --inputWith: 80%;
  }
  .inputGroup {
    flex-direction: column;
    gap: 6px;
  }
}

@media only screen and (max-width: 800px) and (min-width: 500px) {
  :root {
    --inputWith: 70%;
  }
  .inputGroup {
    flex-direction: column;
    gap: 6px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 800px) {
  :root {
    --inputWith: 40%;
  }
}

@media only screen and (min-width: 1200px) {
  :root {
    --inputWith: 30%;
  }
}
