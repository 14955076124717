:root {
  --karsooghPurple: #85bc22;
}

.container {
  display: flex;
  height: 91vh;
  justify-content: center;
  align-items: center;
}

.outerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color:blue; */
}

.img {
  width: 60%;
  /* background-color: aqua; */
}

.errorTitle {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.errorTitle > :first-child {
  font-size: 35px;
  font-weight: 600;
}

@media only screen and (max-width: 500px) {
  .img {
    width: 50%;
    /* background-color: aqua; */
  }
  .errorTitle > :first-child {
    font-size: 25px;
    font-weight: 600;
  }
}
