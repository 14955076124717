:root {
  --registerGreen: #85bb21;
  --purpleTheme: #7a27ff;
}

.container {
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 91vh;
  margin: 0;
  padding: 0;
}
.outerBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  padding: 30px;
  border: 2px dashed rgb(80, 80, 80);
  border-radius: 8px;
  /* background-color: bisque; */
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1px;
  width: 100%;
}

.buttonGroup > button {
  width: 100%;
  border: none;
  padding: 10px;
  background-color: #c8c8c8;
  color: rgb(58, 58, 58);
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.buttonGroup > :nth-child(1) {
  border-radius: 0px 12px 12px 0px;
}

.buttonGroup > :nth-child(2) {
  border-radius: 0;
}
.buttonGroup > :nth-child(3) {
  border-radius: 0;
}
.buttonGroup > :nth-child(4) {
  border-radius: 12px 0px 0px 12px;
}

.shownData {
  /* background-color: blueviolet; */
  width: 100%;
}

.innerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
  gap: 10px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.inputWrapper > span {
  font-size: 12px;
}

.inputGroup > .inputWrapper > input {
  width: 100%;
  margin-left: 3px;
  border: 1px solid rgb(222, 222, 222);
  border: 1px solid rgb(222, 222, 222);
  border-radius: 4px;
  font-size: 16px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: rgb(59, 59, 59);
}

.inputGroup > .inputWrapper > input::placeholder {
  font-size: 15px;
  text-align: right;
  color: rgb(222, 222, 222);
}

.inputGroup > .inputWrapper > input:focus {
  border: 1px solid rgb(222, 222, 222);
  outline: none;
}

.paymentButton {
  width: 100%;
  margin-top: 3px;
  color: white;
  background-color: var(--registerGreen);
  border: none;
  border-radius: 6px;
  padding: 5px;
  font-size: 22px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}

.activateBtn {
  background-color: var(--purpleTheme) !important;
  color: white !important;
}

.isNotPaid {
    background-color: rgba(158, 25, 25, 0.89) !important;
}


.txtWithIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3px;
}