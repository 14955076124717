* {
  margin: 0;
  padding: 0;
  transition: all 100ms;
}

.Container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.Whole-LeftSide {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100vh;
  width: 33.33%; 
}

.Whole-RightSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 66.66%;
}

.CongratsText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 40px;
  text-align: center;
}

.CongratsTitle {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
}

@media (max-width: 540px) {
  .CongratsText {
    padding: 20px;
  }
  .CongratsTitle {
    font-size: 24px;
  }
}

.CongratsText > span {
  text-align: center;
}

.TextDownload {
  margin-top: 50px;
}

.GilPicture {
  width: 700px;
}

.ButtonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  gap: 1px;
}

.ResultButton {
  border-radius: 2px 10px 10px 2px;
}

.MainSiteButton {
  border-radius: 10px 2px 2px 10px;
  background-color: rgb(161, 19, 19) !important;
}

.ButtonGroup > button:hover {
  scale: 0.97;
}

.ButtonGroup > button {
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #85bc22;
  color: white;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: scale 100ms;
}

@media (max-width: 409px) {
  .Whole-LeftSide {
    display: none;
  }
  .Whole-RightSide {
    width: 100%;
  }

  .CongratsTitle {
    text-align: center;
    font-size: 20px;
    width: 100%;
  }

  .CongratsText {
    width: 100%;
    padding: 10px;
    padding-bottom: 0;
    padding-top: 0;
  }
}

@media (min-width: 410px) and (max-width: 539px) {
  .Whole-LeftSide {
    display: none;
  }
  .Whole-RightSide {
    width: 100%;
  }

  .CongratsTitle {
    text-align: center;
    font-size: 25px;
    width: 100%;
  }

  .CongratsText {
    width: 100%;
    padding: 20px;
    padding-bottom: 0;
    padding-top: 0;
  }
}

@media (min-width: 540px) and (max-width: 814px) {
  .Whole-LeftSide {
    display: none;
  }
  .Whole-RightSide {
    width: 100%;
  }

  .CongratsTitle {
    text-align: center;
    font-size: 25px;
    white-space: pre-line;
  }
  .CongratsText {
    width: 100%;
    padding: 0px;
  }
}

@media (min-width: 815px) and (max-width: 1023px) {
  .GilPicture {
    width: 400px;
  }

  .CongratsTitle {
    text-align: center;
    font-size: 24px;
    white-space: nowrap;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .GilPicture {
    width: 550px;
  }

  .CongratsTitle {
    text-align: center;
    font-size: 27px;
    width: 100%;
  }

  .CongratsText {
    padding: 40px;
    padding-bottom: 0;
    padding-top: 0;
  }
}

@media (min-width: 1440px) {
  /* Styles for screens that are 1440px wide or more */
}
